import {groupButtonType} from "../models/detailsGroupTypes";

export const GROUP_BY_BUTTONS: groupButtonType[] = [
  {
    title: 'By day',
    value: 'day'
  },
  {
    title: 'By month',
    value: 'month'
  }
];