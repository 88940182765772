import React, {useRef, useState} from 'react';
import HelpButton from '../../../../../Components/HelpButton';
import BasicButton from '../../../../../../../UI/BasicButton';
import {getDatesListFromCountry, getKeysFromCountry} from '../../../../../Helpers/CreateOrderHelpers';
import {ICountryModel, IKeyword} from '../../../../../Models/CountryModel';
import {throwNewToastError} from '../../../../../../../Utils/MainUtils';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {getDatesBetween} from '../../../../../../../Utils/DateUtils';
import styled from './styled.module.scss';
import newOrderBasics from '../../../../../Styles/newOrderBasics.module.scss';
import basicElements from '../../../../../../../Theme/basicElements.module.scss'


interface ICountryKeywordsAdditionBlockProps {
  handleRemoveCurrentCountry: () => void;
  country: ICountryModel;
  handleSetCountryKeywords: (keywords: IKeyword) => void;
}

const CountryKeywordsAdditionBlock: React.FC<ICountryKeywordsAdditionBlockProps> = ({
                                                                                      handleRemoveCurrentCountry,
                                                                                      country,
                                                                                      handleSetCountryKeywords
                                                                                    }) => {
  const [textAreaValues, setTextAreaValues] = useState<string[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const {dateStart, dateStop} = useAppSelector(state => state.newOrderSlice);

  const getKeywordsFromTextarea = (textareaValue: string): string[] => {
    const lines = textareaValue.split('\n');
    const keywords: string[] = [];

    lines.forEach(line => {
      const trimmedLine = line.trim().toLowerCase();

      if (trimmedLine !== '') {
        const splitKeywords = trimmedLine.split(',').map(keyword => keyword.trim()).filter(keyword => keyword !== '');
        keywords.push(...splitKeywords);
      }
    });

    return keywords;
  };

  const handleAddNewKeysFromTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValues(getKeywordsFromTextarea(event.target.value));
  };

  const dateListsCheck = (newDateArray: string[]): boolean => {
    const oldDateList = getDatesListFromCountry(country);
    const newDateList = newDateArray;

    if (oldDateList.length === 0) {
      return true;
    }

    if (oldDateList.length !== newDateList.length) {
      return false;
    }

    return oldDateList.every((date, index) => date === newDateList[index]);
  };

  const handleAddNewKeywords = () => {
    //функция добавления новых ключей в страну
    if (!dateStart || !dateStop) {
      throwNewToastError('Please select start and end date');
      return;
    }
    if (!textAreaValues.length) {
      throwNewToastError('Enter the keywords');
      return;
    }

    const dateArray = getDatesBetween(dateStart, dateStop);

    let newCountryKeywords: IKeyword = JSON.parse(JSON.stringify(country.keywords));

    //проверка на соответствие массивов дат (если даты изменились, старые ключи остаются, новые добавляются, дата применяется новая)
    if (dateListsCheck(dateArray)) {
      dateArray.forEach(date => {
        if (!newCountryKeywords[date]) {
          newCountryKeywords[date] = {};
        }
        textAreaValues.forEach(key => newCountryKeywords[date][key] = 0);
      });
    } else {
      const oldKeys = getKeysFromCountry(country);
      const allKeysArray = [...oldKeys, ...textAreaValues];

      newCountryKeywords = {};

      dateArray.forEach(date => {
        if (!newCountryKeywords[date]) {
          newCountryKeywords[date] = {};
        }
        allKeysArray.forEach(key => newCountryKeywords[date][key] = 0);
      });
    }

    setTextAreaValues([]);
    if (textAreaRef.current) {
      textAreaRef.current.value = '';
    }

    handleSetCountryKeywords(newCountryKeywords);
  };

  return (
    <div className={styled.keywords_control_block}>
      <div className={newOrderBasics.basic_order_form_row + ' ' + styled.keywords_addition_row}>
        <div className={newOrderBasics.basic_order_form_label + ' ' + styled.keywords_addition_label}>
          <span className={newOrderBasics.basic_order_form_row_title + ' ' + styled.keywords_addition_title}>Keywords <span className={basicElements.basic__red_accent_span}>*</span></span>
          <HelpButton
            popupText={'Enter keywords. Each individual keyword must be on a new line or separated by commas.'}/>
        </div>
        <textarea
            className={styled.keywords_addition_textarea}
          rows={3}
          ref={textAreaRef}
          onChange={handleAddNewKeysFromTextarea}
        />
      </div>
      <BasicButton onClick={handleAddNewKeywords} size={'md'} text={'Save keywords'} variant={'blue'}/>
      <BasicButton onClick={handleRemoveCurrentCountry} size={'md'} text={'Remove country'} variant={'red'}/>
    </div>
  );
};

export default CountryKeywordsAdditionBlock;