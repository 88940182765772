import React from 'react';
import HelpButton from '../../../../../Components/HelpButton';
import BasicButton from '../../../../../../../UI/BasicButton';
import styled from './styled.module.scss';
import newOrderBasics from '../../../../../Styles/newOrderBasics.module.scss';

interface ICountryPackageAdditionBlockProps {
  handleRemoveCurrentCountry: () => void;
}

const CountryPackageAdditionBlock: React.FC<ICountryPackageAdditionBlockProps> = ({handleRemoveCurrentCountry}) => {
  return (
    <div className={styled.package_control_block}>
      <div className={newOrderBasics.basic_order_form_row + ' ' + styled.package_control_row}>
        <div className={newOrderBasics.basic_order_form_label + ' ' + styled.package_addition_label}>
          <span className={newOrderBasics.basic_order_form_row_title}>Installs</span>
          <HelpButton
            popupText={'The "Installations by package" campaign type is selected. Specify the required number of installations.'}/>
        </div>
      </div>
      <BasicButton onClick={handleRemoveCurrentCountry} size={'md'} text={'Remove country'} variant={'red'}/>
    </div>
  );
};

export default CountryPackageAdditionBlock;