import React, {FC, useEffect, useRef} from 'react';
import styles from './DetailsCountriesSelector.module.scss';
import {clsx} from 'clsx';
import {AppStatisticData} from "../../models/detailsGroupTypes";
import {getCountryNameFromISO} from "../../../../../../Utils/countryHelpers";

interface IDetailsCountriesSelectorProps {
  detailCountry: string;
  handleSetCountryISO: (ISO: string | null) => void;
  countriesData: AppStatisticData | null;
}

const DetailsCountriesSelector: FC<IDetailsCountriesSelectorProps> = ({
                                                                        detailCountry,
                                                                        handleSetCountryISO,
                                                                        countriesData
                                                                      }) => {
  const listRef = useRef<HTMLDivElement>(null);
  const selectedItemRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (listRef.current && selectedItemRef.current) {
      const listRect = listRef.current.getBoundingClientRect();
      const itemRect = selectedItemRef.current.getBoundingClientRect();

      if (itemRect.right > listRect.right) {
        listRef.current.scrollLeft += itemRect.right - listRect.right;
      }

      if (itemRect.left < listRect.left) {
        listRef.current.scrollLeft -= listRect.left - itemRect.left;
      }
    }
  }, [detailCountry]);

  const switchNextCountry = (direction: 'next' | 'prev') => {
    if (!countriesData) return;

    const countriesList = Object.keys(countriesData);

    const selectedCountryIndex = countriesList.indexOf(detailCountry);

    let newIndex = 0;

    switch (direction) {
      case 'next': {
        if (selectedCountryIndex === (countriesList.length - 1)) {
          newIndex = 0;
        } else {
          newIndex = selectedCountryIndex + 1;
        }

        break;
      }
      case "prev": {
        if (selectedCountryIndex === 0) {
          newIndex = countriesList.length - 1;
        } else {
          newIndex = selectedCountryIndex - 1;
        }

        break;
      }
      default:
        break;
    }

    const newCountry = countriesList[newIndex];

    handleSetCountryISO(newCountry);
  };

  return (
    <div className={styles.container}>
      <button
        className={clsx(styles.list_button, styles.prev)}
        onClick={() => switchNextCountry('prev')}
      ></button>
      <div className={styles.list} ref={listRef}>
        {countriesData ? Object.keys(countriesData).map(countryISO =>
          <button
            key={countryISO}
            className={clsx(styles.item, countryISO === detailCountry ? styles.active : '')}
            onClick={() => handleSetCountryISO(countryISO)}
            ref={countryISO === detailCountry ? selectedItemRef : null}
          >
            <span>{getCountryNameFromISO(countryISO)}</span>
          </button>
        ) : null}
      </div>
      <button
        className={clsx(styles.list_button, styles.next)}
        onClick={() => switchNextCountry('next')}
      ></button>
    </div>
  );
};

export default DetailsCountriesSelector;