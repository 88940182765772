import { Modal } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import BlockLoader from "../../../../Components/Loaders/BlockLoader";
import { useAppSelector } from "../../../../Hooks/redux/useAppSelector";
import { ICampaignItem } from "../../../../Models/ApiModels";
import { useLazyGetCampaignsQuery } from "../../../../Store/APIs/Campaign.api";
import { BasicEmptyTableDataText } from "../../../../Theme/basicElements";
import CompaignDetails from "./Components/CompaignDetails/CampaignDetails";
import SettingsTableModal from "./Components/Modals/SettingsTableModal";
import OrdersCampaignItem from "./Components/OrdersCampaignItem";
import OrdersTableHead from "./Components/OrdersTableHead";
import {
  OrdersTable,
  OrdersTableContainer,
  OrdersTableScrollWrapper,
  OrdersTbody,
} from "./styled";

let ordersListIsEnded = false;

const OrdersTableModule = memo(() => {
  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  const [fetchOffset, setFetchOffset] = useState(0);
  const [fetchLimit, setFetchLimit] = useState(20);

  const [ordersList, setOrdersList] = useState<ICampaignItem[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const { selectedAppId, selectedStatus, dateStart, dateStop } = useAppSelector(
    (state) => state.myOrdersFiltersSlice
  );

  const [getOrdersList, { isLoading }] = useLazyGetCampaignsQuery();

  useEffect(() => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const fetchNewOrdersList = async () => {
    ordersListIsEnded = false;

    await getOrdersList({
      app: selectedAppId,
      status: selectedStatus,
      dateStart: dateStart,
      dateStop: dateStop,
      offset: 0,
      limit: 21,
    }).then(({ data }) => {
      if (data) {
        setOrdersList(data);
        setFetchOffset(21);

        if (data?.length < 21) {
          ordersListIsEnded = true;
        }
      }
    });
  };

  useEffect(() => {
    ordersListIsEnded = false;
    setOrdersList([]);
    setFetchOffset(0);
    setIsFetching(true);
  }, [selectedAppId, selectedStatus, dateStart, dateStop]);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const isEndReached =
        container.scrollHeight -
          (container.scrollTop + container.clientHeight) <
        100;

      if (isEndReached && !ordersListIsEnded) {
        setIsFetching(true);
      }
    }
  };

  useEffect(() => {
    if (!isFetching) return;

    getOrdersList({
      app: selectedAppId,
      status: selectedStatus,
      dateStart: dateStart,
      dateStop: dateStop,
      offset: fetchOffset,
      limit: fetchLimit,
    })
      .then(({ data }) => {
        if (data) {
          setOrdersList([...ordersList, ...data]);
          setFetchOffset(fetchOffset + 20);

          if (data?.length !== 20) {
            ordersListIsEnded = true;
          }
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [isFetching]);

  const handleToggleSettingsModal = () => {
    setOpenSettingsModal(!openSettingsModal);
  };

  const [idDetails, setIdDetails] = useState<number | null>(null);
  const [nameDetails, setNameDetails] = useState<string>("");
  const [imgDetails, setImgDetails] = useState<string>("");

  const handleSetDetails = (
    id: number | null,
    appName: string,
    appIcon: string
  ) => {
    setIdDetails(id);
    setNameDetails(appName);
    setImgDetails(appIcon);
  };

  return (
    <>
      <OrdersTableContainer>
        <OrdersTableScrollWrapper ref={scrollContainerRef}>
          <OrdersTable>
            <OrdersTableHead
              handleOpenSettingsModal={handleToggleSettingsModal}
            />
            {!!ordersList.length && !isLoading && (
              <OrdersTbody>
                <tr style={{ padding: "5px", display: "block" }} />
                {ordersList.map((order) => (
                  <OrdersCampaignItem
                    key={order.id}
                    orderInfo={order}
                    fetchNewOrdersList={fetchNewOrdersList}
                    detailsHandle={handleSetDetails}
                  />
                ))}
              </OrdersTbody>
            )}
          </OrdersTable>
          {!ordersList.length && isFetching && <BlockLoader size={"lg"} />}
          {!ordersList.length && !isLoading && !isFetching && (
            <BasicEmptyTableDataText>No orders</BasicEmptyTableDataText>
          )}
        </OrdersTableScrollWrapper>
      </OrdersTableContainer>
      <Modal open={openSettingsModal} onClose={handleToggleSettingsModal}>
        <SettingsTableModal
          handleToggleSettingsModal={handleToggleSettingsModal}
        />
      </Modal>
      <Modal
        open={!!idDetails}
        onClose={() => handleSetDetails(null, " ", " ")}
      >
        <CompaignDetails
          idDetails={idDetails}
          imgDetails={imgDetails}
          nameDetails={nameDetails}
          onClose={() => handleSetDetails(null, " ", " ")}
        />
      </Modal>
    </>
  );
});

export default OrdersTableModule;
