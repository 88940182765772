import React, {FC} from 'react';
import styles from './GroupBy.module.scss';
import {groupButtonType} from "../../models/detailsGroupTypes";

interface GroupBySelectorProps {
  groups: groupButtonType[];
  selectedGroup: groupButtonType["value"];
  onSelect: (group: groupButtonType["value"]) => void;
}

const GroupBySelector: FC<GroupBySelectorProps> = ({groups, selectedGroup, onSelect}) => {

  return (
    <div className={styles.selector}>
      {groups.map(group =>
        <button className={styles.button}
                onClick={() => onSelect(group.value)}
                disabled={selectedGroup === group.value}
                key={group.value}>{group.title}</button>
      )}
    </div>
  );
};

export default GroupBySelector;