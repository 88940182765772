import {
    ATTRIBUTES_VALUES
} from "../Pages/CreateCampaignPage/Presentor/Modules/CreateCampaignAttributesView/Constants/AttributesValues";

const BitParser = (attributesValue: number) => {
    const list: string[] = [];

    for (let attribute in ATTRIBUTES_VALUES) {
        if ((attributesValue & ATTRIBUTES_VALUES[attribute]) !== 0) {
            list.push(attribute);
        }
    }
    return list;
};

export default BitParser;