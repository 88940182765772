import React, {ForwardedRef, useEffect, useState} from "react";
import styled from "./styled.module.scss";
import {
  IGetStatisticGroupResponseItem,
  useGetCampaignInfoQuery,
} from "@/Store/APIs/Campaign.api";
import modalsElements from "@/Theme/modalsElements.module.scss";
// import basicElements from '../../../../../../Theme/basicElements.module.scss';
// import { ICampaignInfo } from "../../../../../../Models/ApiModels";
import bitParser from "@/Utils/BitParser";
import {getStatisticDetailsData} from "../../../OrdersStatisticModule/utils";
import {ITableRows} from "../../../OrdersStatisticModule/models/detailsGroupTypes";
import cross from "@/Images/svg/remove-cross.svg";
import BlockLoader from "@/Components/Loaders/BlockLoader";

interface ICampaignDetails {
  idDetails: number | null;
  nameDetails: string;
  imgDetails: string;
  onClose: () => void;
}

interface ListInfoItem {
  [key: string]: string | string[];
}

const CampaignDetails = React.forwardRef(
  (
    {idDetails, nameDetails, imgDetails, onClose}: ICampaignDetails,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const {data, isSuccess, isLoading} = useGetCampaignInfoQuery(idDetails);
    const [tableRows, setTableRows] = useState<ITableRows>();
    const [listInfo, setListInfo] = useState<ListInfoItem>({
      Type: "",
      "Console visibility": "",
      "Launch app": "",
      Distribute: "",
      "Delayed start": "",
    });

    const delayStartCheck = (time: string | null, date: string): string => {
      switch (time) {
        case null:
          return "";
        default:
          return date + " at " + time.split(" ")[0];
      }
    };

    const getOrdinal = (n: number): string => {
      if (n > 3 && n < 15) return n + "th";
      switch (n % 10) {
        case 1:
          return n + "st";
        case 2:
          return n + "nd";
        case 3:
          return n + "rd";
        default:
          return n + "th";
      }
    };

    const dayParser = (data: number) => {
      const daysArray: string[] = bitParser(data);
      const dataInfo = {
        distribute: "",
        consoleVisible: "",
        days: [] as string[],
      };

      daysArray.forEach((item) => {
        switch (item) {
          case "distribute":
            dataInfo.distribute = "Throughout the day";
            break;
          case "consoleVisible":
            dataInfo.consoleVisible = "on";
            break;
          default:
            const match = item.match(/\d+/);
            if (match) {
              const dayNumber = parseInt(match[0], 10);
              if (!isNaN(dayNumber)) {
                dataInfo.days.push(getOrdinal(dayNumber));
              }
            }
        }
      });

      if (dataInfo.days.length) {
        return {
          ...dataInfo,
          days: dataInfo.days.join(", ") + " Days",
        };
      }

      return dataInfo;
    };

    useEffect(() => {
      if (isSuccess && data) {
        const parsedData = dayParser(data.settings_message);
        setListInfo({
          Type: data.type,
          "Console visibility": parsedData.consoleVisible,
          "Launch app": parsedData.days,
          Distribute: parsedData.distribute,
          "Delayed start": delayStartCheck(data.delayed_start, data.date_start),
        });
        const array: IGetStatisticGroupResponseItem[] = [];
        data.keywords.forEach((item) => {
          array.push({
            country: item.country,
            date: item.date,
            keyword: item.keyword,
            value: item.value.toString(),
          });
        });

        setTableRows(getStatisticDetailsData(array, {countryTotal: true}));
      }
    }, [isSuccess, data]);

    return (
      <div
        className={
          modalsElements.modal_wrapper_full_height +
          " " +
          styled.details_wrapper
        }
        ref={ref}
        tabIndex={-1}
      >
        <div className={styled.details__title_wrapper}>
          <p className={styled.details__title}>
            Campaign
            <u className={styled.details__title_id}>{idDetails}</u>
            details
          </p>
        </div>
        <div className={styled.details__logo_wrapper}>
          <img
            src={imgDetails}
            alt={"Application logo"}
            className={styled.details__logo}
          />
          <span>{nameDetails}</span>
        </div>
        <ul className={styled.details__list_info}>
          {Object.entries(listInfo)
            .filter(
              ([_, itemInfo]) =>
                itemInfo !== "" &&
                (Array.isArray(itemInfo) ? itemInfo.length > 0 : true)
            )
            .map(([itemTitle, itemInfo], index) => (
              <li className={styled.details__list_item} key={index}>
                <span className={styled.details__item_title}>{itemTitle}</span>
                <span className={styled.details__item_info}>{itemInfo}</span>
              </li>
            ))}
        </ul>
        {data !== undefined && (
          <div className={styled.details__campaign_dates_wrapper}>
            <p className={styled.details__campaign_dates}>
              From
              <span className={styled.details__campaign_date}>
                {data.date_start}
              </span>
              To
              <span className={styled.details__campaign_date}>
                {data.date_stop}
              </span>
            </p>
          </div>
        )}
        {isLoading ? (
          <div className={styled.loader_wrapper}>
            <BlockLoader size="lg"/>
          </div>
        ) : (
          <div className={styled.details__table_wrapper}>
            <table>
              <thead>
              <tr>
                {/* строка заголовков дат*/}
                <th className={styled.sticky_column_country}>Country</th>
                <th className={styled.sticky_column_keys}>Keys</th>
                {tableRows !== undefined && (
                  <>
                    {tableRows.dateList.map((item, index) => (
                      <th key={index}>{item}</th>
                    ))}
                  </>
                )}
              </tr>
              </thead>

              <tbody>
              {/* строка тела - страна\кейворд\строка значений */}
              {tableRows !== undefined &&
                tableRows.table.map((item, index) =>
                  item.keyword !== "Total" ? (
                    <tr key={index}>
                      {Object.values(item).map((value, index) => (
                        <td
                          className={`${styled.details__table_regular_cell} ${
                            index === 0
                              ? styled.sticky_column_country
                              : index === 1
                                ? styled.sticky_column_keys
                                : ""
                          }`}
                          key={index}
                        >
                          {value === "#install_by_package#"
                            ? "Package Installs"
                            : value}
                        </td>
                      ))}
                    </tr>
                  ) : (
                    ""
                  )
                )}
              {/* строка total всех стран */}
              {tableRows !== undefined && (
                <tr>
                  <td
                    className={
                      styled.details__table_total_cell +
                      " " +
                      styled.sticky_column_country
                    }
                  >
                    {/*total*/}
                  </td>
                  {Object.values(tableRows.footer).map(
                    (value, index) =>
                      value !== "" && (
                        <td
                          key={index}
                          className={`${styled.details__table_total_cell} ${
                            index === 0
                              ? styled.sticky_column +
                              " " +
                              styled.sticky_column_keys
                              : ""
                          }`}
                        >
                          {value}
                        </td>
                      )
                  )}
                </tr>
              )}
              </tbody>
            </table>
          </div>
        )}

        <button className={styled.close_button} onClick={onClose}>
          <img src={cross} alt="Close"/>
        </button>
      </div>
    );
  }
);

export default CampaignDetails;
