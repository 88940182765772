import React, {FC} from 'react';
import BasicButton from "../../../../../../UI/BasicButton";
import {getStatisticDetailsData} from "../../utils";
import {utils, writeFile} from "xlsx";
import {useAppSelector} from "@/Hooks/redux/useAppSelector";
import {IGetStatisticGroupResponseItem} from "@/Store/APIs/Campaign.api";

interface ExportDetailsButtonProps {
  appStatisticData: IGetStatisticGroupResponseItem[];
}

const ExportDetailsButton: FC<ExportDetailsButtonProps> = ({appStatisticData}) => {
  const {appInfo, dateStart, dateStop} = useAppSelector(state => state.myOrdersStatisticSlice);

  const handleExport = () => {
    if (!appStatisticData) return;

    const newTableRows = getStatisticDetailsData(appStatisticData, {countryTotal: false});

    const headerRow = ['Country', 'Key', ...newTableRows.dateList];
    const worksheetData = [headerRow];

    newTableRows.table.forEach(row => {
      const {country, keyword, ...dates} = row;

      const sheetRow: string[] = [country, keyword];

      newTableRows.dateList.forEach(date => {
        sheetRow.push(String(dates[date]));
      });

      worksheetData.push(sheetRow);
    });

    const footerRow = ['Total', '', ...newTableRows.dateList.map(date => String(newTableRows.footer[date]))];

    worksheetData.push(footerRow);

    const ws = utils.aoa_to_sheet(worksheetData);
    const wb = utils.book_new();

    utils.book_append_sheet(wb, ws, `Data`);

    writeFile(wb, `${appInfo?.application_name}_${dateStart}-${dateStop}.xlsx`);
  };

  return (
    <BasicButton onClick={handleExport} size={'md'} text={'Export xlsx'} variant={'blue'}/>
  );
};

export default ExportDetailsButton;