import React, {FC, useEffect, useState} from 'react';
import listsStyles from '../Lists.module.scss';
import styles from './Detail.module.scss';
import basicModal from '../../../../../../../Theme/modalsElements.module.scss';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {
  IGetStatisticGroupResponseItem,
  useLazyGetStatisticGroupQuery
} from '../../../../../../../Store/APIs/Campaign.api';
import {useActions} from '../../../../../../../Hooks/redux/useActions';
import BasicStoreIcon from '../../../../../../../UI/icons/BasicStoreIcon';
import {AppStatisticData, GroupByType} from "../../../models/detailsGroupTypes";
import StatisticDateSelector from "../../StatisticDateSelector/StatisticDateSelector";
import CountriesDetails from "../../CountriesDetails/CountriesDetails";
import {getCountriesStatistic} from "../../../utils";
import {getCountryNameFromISO} from "../../../../../../../Utils/countryHelpers";
import CountryKeywordsDetails from "../../CountryKeywordsDetails/CountryKeywordsDetails";

interface DetailAppStatisticProps {
  detailId: number;
}

const DetailAppStatistic: FC<DetailAppStatisticProps> = ({detailId}) => {
  const {appInfo, dateStart, dateStop} = useAppSelector(state => state.myOrdersStatisticSlice);
  const [appStatisticData, setAppStatisticData] = useState<IGetStatisticGroupResponseItem[]>([]);
  const [countriesData, setCountriesData] = useState<AppStatisticData | null>(null);
  const [detailCountryISO, setDetailCountryISO] = useState<string | null>(null);
  const [groupBy, setGroupBy] = useState<GroupByType>('day');

  const {setAppInfo} = useActions();

  const [getStatistics, {isLoading, isSuccess}] = useLazyGetStatisticGroupQuery();

  useEffect(() => {
    getStatistics({
      app: detailId,
      dateStart,
      dateStop,
      groupBy
    }).unwrap()
      .then(result => {
        setAppStatisticData(result);

        if (!result.length) {
          setCountriesData(null);
          return;
        }

        const newTableData = getCountriesStatistic(result);

        setCountriesData(newTableData);
      });
  }, [dateStart, dateStop, detailId, groupBy]);

  const handleChangeGroup = (group: GroupByType) => {
    setGroupBy(group);
  };

  const handleSetCountryISO = (ISO: string | null) => {
    setDetailCountryISO(ISO);
  };

  const goBackHandler = () => {
    if (detailCountryISO) {
      setDetailCountryISO(null);
      return;
    }

    if (appInfo) {
      setAppInfo(null);
      return;
    }
  };

  return (
    <div className={listsStyles.wrapper}>
      {appInfo ?
        <>
          <div className={styles.heading}>
            <button className={styles.return_button} onClick={goBackHandler}>
              <span>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M11.2808 4.23678C11.4937 4.43204 11.4937 4.74862 11.2808 4.94388L7.69043 8.23678C7.47753 8.43204 7.47753 8.74862 7.69043 8.94388L11.2808 12.2368C11.4937 12.432 11.4937 12.7486 11.2808 12.9439C11.0679 13.1391 10.7227 13.1391 10.5098 12.9439L6.91945 9.65099C6.28076 9.06521 6.28075 8.11546 6.91945 7.52967L10.5098 4.23678C10.7227 4.04152 11.0679 4.04152 11.2808 4.23678Z"
                        fill="#A7A7A7"/>
                </svg>
              </span>
            </button>
            <div className={styles.header}>
              <div className={styles.store}>
                <BasicStoreIcon store={appInfo.application_store_id.includes('.') ? 'google' : 'apple'}/>
              </div>
              <h3
                className={basicModal.modal_title + ' ' + styles.title}>{appInfo.application_name} details
                {detailCountryISO ? ` (${getCountryNameFromISO(detailCountryISO)})` : null}</h3>
              <div className={styles.date_selector}>
                <StatisticDateSelector/>
              </div>
            </div>
          </div>
          {detailCountryISO ?
            <CountryKeywordsDetails
              selectedGroup={groupBy}
              handleChangeGroup={handleChangeGroup}
              detailCountry={detailCountryISO}
              appStatisticData={appStatisticData}
              countriesData={countriesData}
              handleSetCountryISO={handleSetCountryISO}
            /> :
            <CountriesDetails
              appStatisticData={appStatisticData}
              countriesData={countriesData}
              appInfo={appInfo}
              isLoading={isLoading}
              isSuccess={isSuccess}
              dateStart={dateStart}
              dateStop={dateStop}
              handleSetCountryISO={handleSetCountryISO}
            />
          }
        </> : null
      }
    </div>
  );
};

export default DetailAppStatistic;