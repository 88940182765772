import React from 'react';
import {DAYS_ATTRIBUTES} from '../../Constants/DaysAttributes';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import styled from './styled.module.scss';

interface IDaysListAttributesProps {
  handleSetSelectedCheckboxesValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled: boolean;
  selectedCheckboxesValues: string[];
}

const DaysListAttributes: React.FC<IDaysListAttributesProps> = ({
                                                                  handleSetSelectedCheckboxesValue,
                                                                  selectedCheckboxesValues,
                                                                  isDisabled
                                                                }) => {

  return (
    <div className={styled.days_attribute_body}>
      <div className={styled.days_attribute_row}>
        {DAYS_ATTRIBUTES.slice(0, 7).map(day => (
          <div className={styled.days_attribute_item} key={day.value}>
            <label className={styled.days_attribute_value} data-is-disabled={isDisabled} htmlFor={day.value}>{day.title}</label>
            <BasicCheckboxButton
              isDisabled={isDisabled}
              name={'days'}
              value={day.value}
              checked={!!selectedCheckboxesValues.find(attribute => attribute === day.value)}
              onChange={handleSetSelectedCheckboxesValue}
            />
          </div>
        ))}
      </div>
      <div className={styled.days_attribute_row}>
        {DAYS_ATTRIBUTES.slice(7, 14).map(day => (
          <div className={styled.days_attribute_item} key={day.value}>
            <label className={styled.days_attribute_value} data-is-disabled={isDisabled} htmlFor={day.value}>{day.title}</label>
            <BasicCheckboxButton
              isDisabled={isDisabled}
              name={'days'}
              value={day.value}
              checked={!!selectedCheckboxesValues.find(attribute => attribute === day.value)}
              onChange={handleSetSelectedCheckboxesValue}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DaysListAttributes;