import React, {FC} from 'react';
import GroupBySelector from "../GroupBySelector/GroupBySelector";
import {GROUP_BY_BUTTONS} from "../../const/groupsBy";
import {AppStatisticData, GroupByType} from "../../models/detailsGroupTypes";
import KeywordsTable from "../tables/KeywordsTable/KeywordsTable";
import listsStyles from "../lists/Lists.module.scss";
import {IGetStatisticGroupResponseItem} from "../../../../../../Store/APIs/Campaign.api";
import ExportDetailsButton from "../ExportDetailsButton/ExportDetailsButton";
import DetailsCountriesSelector from "../DetailsCountriesSelector/DetailsCountriesSelector";

interface CountryKeywordsDetailsProps {
  selectedGroup: GroupByType;
  countriesData: AppStatisticData | null;
  handleChangeGroup: (group: GroupByType) => void;
  appStatisticData: IGetStatisticGroupResponseItem[];
  detailCountry: string;
  handleSetCountryISO: (ISO: string | null) => void;
}

const CountryKeywordsDetails: FC<CountryKeywordsDetailsProps> = ({
                                                                   selectedGroup,
                                                                   handleChangeGroup,
                                                                   detailCountry,
                                                                   appStatisticData,
                                                                   countriesData,
                                                                   handleSetCountryISO
                                                                 }) => {


  return (
    <>
      <GroupBySelector groups={GROUP_BY_BUTTONS} selectedGroup={selectedGroup} onSelect={handleChangeGroup}/>
      <div className={listsStyles.table_block}>
        {!countriesData ? <span className={listsStyles.empty_text}>No data</span> :
          <KeywordsTable detailCountry={detailCountry} countriesData={countriesData}/>
        }
      </div>
      <div className={listsStyles.export_block}>
        <ExportDetailsButton appStatisticData={appStatisticData}/>
      </div>
      <div className={listsStyles.countries_selector}>
        <DetailsCountriesSelector
          countriesData={countriesData}
          detailCountry={detailCountry}
          handleSetCountryISO={handleSetCountryISO}
        />
      </div>
    </>
  );
};

export default CountryKeywordsDetails;