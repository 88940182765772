import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {IAddNewAppData, IAppItem, IRemoveAppData} from '../../Models/ApiModels';
import {API_URL} from '../../Constants/ApiConstants';
import {errorsActions} from '../Slices/errors.slice';
import {LS_USER_TOKEN} from '../../Constants/LSConstants';
import {authActions} from '../Slices/auth.slice';

interface IGetAppsParams {
  offset?: number,
  limit?: number,
  search?: string | null
}

interface IGetStatisticParams {
  id: number,
  dateStart: string,
  dateStop: string
}

interface IAddNewAppResponse {
  status: number;
  id: string;
}

export interface IStatisticResponse {
  key: string;
  country: string;

  [date: string]: string;
}

const {addError} = errorsActions;
const {setAuthenticated} = authActions;

export const AppsApi = createApi({
  reducerPath: 'AppsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + '/apps',
    prepareHeaders: headers => {
      const token: string | null = localStorage.getItem(LS_USER_TOKEN);

      if (token) headers.set('token', token);
    }
  }),
  tagTypes: ['AppList'],
  endpoints: (build) => ({
    getApps: build.query<IAppItem[], IGetAppsParams>({
      query: ({offset = 0, limit = 999, search}) => ({
        url: `/get`,
        params: {
          offset,
          limit,
          ...(search && ({search}))
        }
      }),
      providesTags: () => ['AppList'],
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(addError(error.error));
          if (error.error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        }
      }
    }),
    addNewApp: build.mutation<IAddNewAppResponse, IAddNewAppData>({
      query: ({appid, country = null}) => ({
        url: '/create',
        method: 'put',
        body: {
          appid,
          ...(country !== null && {country: country})
        }
      }),
      invalidatesTags: () => ['AppList']
    }),
    getAppStatistic: build.query<IStatisticResponse[], IGetStatisticParams>({
      query: ({id, dateStart, dateStop}) => ({
        url: '/statistic',
        params: {
          dateStart,
          dateStop,
          id
        }
      }),
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(addError(error.error));
        }
      },
    }),
    removeApp: build.mutation<any, IRemoveAppData>({
      query: ({id}) => ({
        url: 'delete',
        method: 'delete',
        body: {
          id: id
        }
      }),
      invalidatesTags: () => ['AppList'],
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(addError(error.error));
        }
      },
    })
  })
});

export const {useGetAppsQuery, useRemoveAppMutation, useGetAppStatisticQuery, useAddNewAppMutation} = AppsApi;