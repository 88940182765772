import React, {forwardRef} from 'react';
import BasicButton from '../../../../UI/BasicButton';
import {useAppSelector} from '../../../../Hooks/redux/useAppSelector';
import {useGetAppsQuery} from '../../../../Store/APIs/Apps.api';
import {
  getTotalCostValue,
  getTotalCurrentTypeName,
  getTotalKeywordsCountFromCountries
} from '../../Helpers/CreateOrderHelpers';
import RemoveCrossButton from '../../../../UI/RemoveCrossButton';
import styled from './styled.module.scss';
import modalsElements from '../../../../Theme/modalsElements.module.scss';
import basicElements from '../../../../Theme/basicElements.module.scss';


interface ICreateNewOrderModalProps {
  handleSubmitCreateNewOrder: () => void;
  onClose: () => void;
}

const CreateNewOrderModal = forwardRef(({
                                          handleSubmitCreateNewOrder,
                                          onClose
                                        }: ICreateNewOrderModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {countriesList, app, type, attributesValue} = useAppSelector(state => state.newOrderSlice);
  const {user} = useAppSelector(state => state.clientDataSlice);

  const {data: appsList = []} = useGetAppsQuery({offset: 0, search: '', limit: 999});

  return (
      <div className={modalsElements.modal_wrapper + ' ' + styled.submit_campaign_modal} ref={ref} tabIndex={-1}>
          <div className={modalsElements.close_button_block}>
              <RemoveCrossButton onClick={onClose}/>
          </div>
          <h3 className={modalsElements.modal_title + ' ' + styled.submit_campaign_modal_title}>Are you sure you want to start
            the promotion?</h3>
          <div className={styled.submit_campaign_modal_text_block}>
              <span className={basicElements.basic__content_text + ' ' + styled.submit_campaign_modal_text}>Campaign
                  price: <b>${(getTotalCostValue(app, type, user, appsList, countriesList, attributesValue))?.toFixed(2)}</b></span>
              <span
                  className={basicElements.basic__content_text + ' ' + styled.submit_campaign_modal_text}> Total {!!type && getTotalCurrentTypeName(type)}: <b>{getTotalKeywordsCountFromCountries(countriesList)}</b></span>
          </div>
          <BasicButton onClick={handleSubmitCreateNewOrder} size={'lg'} text={'Ok'} variant={'blue'}/>
      </div>
  );
});

export default CreateNewOrderModal;