import styled from 'styled-components';
import {IThemeProvider} from '../../../../../../../Theme/defaultTheme';

export const KeywordsTable = styled.table`
  overflow: auto;
  white-space: nowrap;

  line-height: 24px;
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};
`;

export const KeywordsTHead = styled.thead`

`;

export const KeywordsTBody = styled.tbody``;

export const KeywordsTr = styled.tr`

`;

export const KeywordsTh = styled.th<IThemeProvider>`
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;

  width: 160px;
    height: 65px;
  max-width: 160px;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.brand_blue};
  padding: 10px 8px;
  border-bottom: 1px solid ${props => props.theme.colors.grey_semi};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.white};
  border-top: 1px solid ${props => props.theme.colors.grey_semi};
  text-align: center;

  &[data-role='title'] {
    left: -1px;
    z-index: 3;
    position: sticky;
    min-width: 150px;
    width: max-content;
    background-color: ${props => props.theme.colors.primary_light};
    color: ${props => props.theme.colors.black};
    text-align: left;
  }
`;

export const KeywordsTd = styled.td`
  white-space: nowrap;
  overflow: hidden;

  position: relative;
  z-index: 2;
  box-sizing: border-box;
  width: 160px;
    height: 65px;
  max-width: 160px;
  background-color: ${props => props.theme.colors.brand_blue};
  padding: 10px 8px;
  border-bottom: 1px solid ${props => props.theme.colors.grey_semi};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  color: ${props => props.theme.colors.white};
  text-align: center;
  line-height: 21px;

  &[data-role='title'] {
    position: sticky;
    left: -1px;
    width: max-content;
    min-width: 150px;
    max-width: unset;
    background-color: ${props => props.theme.colors.primary_light};
    color: ${props => props.theme.colors.black};
    text-align: left;
    z-index: 3;
  }
`;

export const KeywordsTdInput = styled.input<IThemeProvider>`
  width: 144px;
  height: 24px;
  border: 0;
  padding: 6px 8px;
  border-radius: 8px;

  color: ${props => props.theme.colors.brand_blue};
  text-align: center;

  line-height: 18px;
  font-family: ${props => props.theme.font_style.font_family};
  font-weight: ${props => props.theme.font_style.font_weight.regular};
  font-size: ${props => props.theme.font_sizes.content};
`;