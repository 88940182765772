import React, {FC} from 'react';
import listsStyles from "../lists/Lists.module.scss";
import BlockLoader from "@/Components/Loaders/BlockLoader";
import CountriesTable from "../tables/CountriesTable/CountriesTable";
import {IGetStatisticGroupResponseItem, IOrdersStatisticItem} from "@/Store/APIs/Campaign.api";
import {AppStatisticData} from "../../models/detailsGroupTypes";
import ExportDetailsButton from "../ExportDetailsButton/ExportDetailsButton";

interface CountriesDetailsProps {
  appStatisticData: IGetStatisticGroupResponseItem[];
  countriesData: AppStatisticData | null;
  appInfo: IOrdersStatisticItem;
  isLoading: boolean;
  isSuccess: boolean;
  dateStart: string;
  dateStop: string;
  handleSetCountryISO: (date: string | null) => void;
}

const CountriesDetails: FC<CountriesDetailsProps> = ({
                                                       appStatisticData,
                                                       countriesData,
                                                       isLoading,
                                                       isSuccess,
                                                       handleSetCountryISO
                                                     }) => {


  return (
    <>
      <div className={listsStyles.table_block}>
        {isLoading ? <BlockLoader size={'lg'}/> : null}
        {(isSuccess && !countriesData) ?
          <span className={listsStyles.empty_text}>No data</span>
          : <CountriesTable
            handleSetCountryISO={handleSetCountryISO}
            countriesData={countriesData}
          />
        }
      </div>
      <div className={listsStyles.export_block}>
        <ExportDetailsButton appStatisticData={appStatisticData}/>
      </div>
    </>
  );
};

export default CountriesDetails;