import React, {forwardRef} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {getFormattedDate} from '../../Utils/DateUtils';
import ClearInputButton from '../../UI/ClearInputButton';
import en from 'date-fns/locale/en-GB';
import styles from './styled.module.scss';

interface IDateSelector {
  handleGetDateStart: (date: string) => void,
  handleGetDateStop: (date: string) => void,
  dateStart: string,
  dateStop: string,
  cleanable?: boolean
}

interface IExampleCustomInputProps {
  value?: string;
  onClick?: () => void;
  placeholder?: string;
}

registerLocale('en', en);
const DateSelector = ({
                        handleGetDateStart,
                        handleGetDateStop,
                        dateStart,
                        dateStop,
                        cleanable = true
                      }: IDateSelector) => {
  const ExampleCustomInput = forwardRef<HTMLInputElement, IExampleCustomInputProps>(({
                                                                                       value,
                                                                                       onClick,
                                                                                       placeholder
                                                                                     }, ref) => (
    <input className={styles.styled_data_picker} onClick={onClick} ref={ref} value={value} placeholder={placeholder}
           readOnly/>
  ));

  return (
    <div className={styles.date_filter_block}>
      <div className={styles.date_input}>
        <DatePicker
          onChange={(date: Date) => {
            if (date) {
              handleGetDateStart(getFormattedDate(date));
            }
          }}

          selected={dateStart.length ? new Date(dateStart) : null}
          customInput={<ExampleCustomInput/>}
          placeholderText={'Start date'}
          closeOnScroll={true}
          dateFormat="yyyy-MM-dd"
          maxDate={dateStop.length ? new Date(dateStop) : new Date()}
          locale="en"
        />
        {!!dateStart.length && cleanable &&
            <ClearInputButton
                onClick={() => {
                  handleGetDateStart('');
                }}/>}
      </div>
      <div className={styles.date_input}>
        <DatePicker
          onChange={(date: Date) => {
            if (date) {
              handleGetDateStop(getFormattedDate(date));
            }
          }}
          selected={dateStop ? new Date(dateStop) : null}
          customInput={<ExampleCustomInput/>}
          placeholderText={'End date'}
          closeOnScroll={true}
          dateFormat="yyyy-MM-dd"
          maxDate={new Date()}
          minDate={dateStart ? new Date(dateStart) : null}
          locale="en"
        />
        {!!dateStop.length && cleanable &&
            <ClearInputButton
                onClick={() => {
                  handleGetDateStop('');
                }}/>
        }
      </div>
    </div>
  );
};

export default DateSelector;