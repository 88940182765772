import React, {FC, useMemo} from 'react';
import styles from "../tables.module.scss";
import {getKeywordsDetails} from "../../../utils";
import basicStyles from "../../../../../../../Theme/basicElements.module.scss";
import {AppStatisticData} from "../../../models/detailsGroupTypes";

interface KeywordsTableProps {
  detailCountry: string;
  countriesData: AppStatisticData;
}

const KeywordsTable: FC<KeywordsTableProps> = ({detailCountry, countriesData}) => {

  const getMemoizedTableRows = useMemo(() => {
    const {dateList, keywords, total} = getKeywordsDetails(countriesData[detailCountry].keywords);

    const tableHead = (
      <tr>
        <th className={basicStyles.basic__table_cell + ' ' + styles.th + ' ' + styles.full} data-align="left">
          Keywords
        </th>
        {dateList.map(date =>
          <th className={basicStyles.basic__table_cell + ' ' + styles.th} data-align="right" key={date}>
            {date}
          </th>
        )}
      </tr>
    );

    const tableBody = (
      Object.keys(keywords).map(key =>
        <tr className={styles.tr} key={key}>
          <td
            className={basicStyles.basic__table_cell + ' ' + styles.td + ' ' + styles.full}
            data-align={'left'}>
            {key}
          </td>
          {dateList.map(date =>
            <td
              key={key + '-' + date}
              className={basicStyles.basic__table_cell + ' ' + styles.td}
              data-align={'right'}>
              {keywords[key][date]}
            </td>
          )}
        </tr>
      )
    );

    const tableFooter = (
      <tr>
        <td
          className={basicStyles.basic__table_cell + ' ' + styles.td + ' ' + styles.sum + ' ' + styles.full}
          data-align={'left'}
        >
          Total
        </td>
        {dateList.map(date =>
          <td
            key={date + '-' + total[date]}
            className={basicStyles.basic__table_cell + ' ' + styles.td + ' ' + styles.sum}
            data-align={'right'}
          >
            {total[date]}
          </td>
        )}
      </tr>
    );

    return {
      tableHead, tableBody, tableFooter
    };
  }, [detailCountry, countriesData]);

  return (
    <>
      <table className={styles.table}>
        <thead className={styles.thead}>
        {getMemoizedTableRows.tableHead}
        </thead>
        <tbody className={styles.tbody}>
        {getMemoizedTableRows.tableBody}
        </tbody>
        <tfoot className={styles.tfoot}>
        {getMemoizedTableRows.tableFooter}
        </tfoot>
      </table>
    </>
  );
};

export default KeywordsTable;