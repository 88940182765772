import React, {useState} from "react";
import {OrdersTd, OrdersTr} from "../../styled";
import {ICampaignItem} from "@/Models/ApiModels";
import {AppNameBlock, AppNameIcon, AppNameText} from "./styled";
import ProgressItem from "../ProgressItem";
import StatusItem from "../StatusItem";
import OrderTableButton from "../OrderTableButton";
import {Modal, Popover} from "@mui/material";
import CancelOrderModal from "../Modals/CancelOrderModal";
import ArchiveOrderModal from "../Modals/ArchiveOrderModal/ArchiveOrderModal";
import PromotePopover from "../Modals/PromotePopover";
import {useAppSelector} from "@/Hooks/redux/useAppSelector";
import CancelDelayedOrderModal from "../Modals/CancelDelayedOrderModal";

interface OrdersCampaignItemProps {
  orderInfo: ICampaignItem;
  fetchNewOrdersList: () => void;
  detailsHandle: (id: number | null, appName: string, appIcon: string) => void;
}

const OrdersCampaignItem: React.FC<OrdersCampaignItemProps> = ({
                                                                 orderInfo,
                                                                 fetchNewOrdersList,
                                                                 detailsHandle,
                                                               }) => {
  const {
    id,
    appName,
    appIcon,
    geo,
    type,
    count,
    days,
    price,
    startTime,
    status,
  } = orderInfo;

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [openDelayedModal, setOpenDelayedModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const openPopover = Boolean(anchorEl);

  const selectedColumns = useAppSelector((state) => state.myOrdersColumnsSlice);

  const handleToggleCancelModal = () => {
    setOpenCancelModal(!openCancelModal);
  };

  const handleToggleArchiveModal = () => {
    setOpenArchiveModal(!openArchiveModal);
  };

  const handleOpenDelayedModal = () => {
    setOpenDelayedModal(true);
  };

  const handleCloseDelayedModal = () => {
    setOpenDelayedModal(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const getGeoString = (geo: string) => {
    if (!geo) return;

    const geoList = geo.split(",");

    if (geoList.length > 3) {
      return `${geoList.splice(0, 3).join(",")}...`;
    } else {
      return geoList.join(",");
    }
  };

  const getCorrectTypeTitle = (type: string) => {
    const typeList: { [key: string]: string } = {
      install: "Install",
      rate: "Rate",
      install_bp: "Package",
    };

    return typeList[type];
  };

  interface IRowCell {
    key: keyof ICampaignItem | "startDate" | "progress" | "app";
    element?: React.ReactNode;
    title?: string;
  }

  const ROW_CELLS: IRowCell[] = [
    {
      key: "id",
    },
    {
      key: "app",
      element: (
        <AppNameBlock>
          {!appName.includes("banned") && <AppNameIcon src={appIcon}/>}
          <AppNameText>{appName}</AppNameText>
        </AppNameBlock>
      ),
      title: appName,
    },
    {
      key: "geo",
      element: getGeoString(geo),
      title: geo,
    },
    {key: "type", element: getCorrectTypeTitle(type)},
    {
      key: "count",
    },
    {
      key: "days",
    },
    {
      key: "price",
      element: `$ ${price.toFixed(2)}`,
    },
    {key: "startDate", element: startTime},
    {key: "progress", element: <ProgressItem days={days} status={status}/>},
    {key: "status", element: <StatusItem status={status}/>},
  ];

  return (
    <>
      <OrdersTr>
        {ROW_CELLS.map((cell) =>
          selectedColumns[cell.key] ? (
            <OrdersTd
              title={cell.title ? cell.title : ""}
              onClick={() => detailsHandle(id, appName, appIcon)}
            >
              {cell.key === "startDate" ||
              cell.key === "progress" ||
              cell.key === "app"
                ? cell.element
                : cell.element ?? orderInfo[cell.key]}
            </OrdersTd>
          ) : null
        )}
        <OrdersTd>
          <OrderTableButton
            role={"cancel"}
            onClick={handleToggleCancelModal}
            disabled={!(status === 1 || status === 2 || status === 7)}
            title={"Stop the order"}
          />
        </OrdersTd>
        <OrdersTd>
          <OrderTableButton
            role={"promote"}
            onClick={handleOpenPopover}
            title={"Duplicate or extend the campaign"}
            disabled={appName?.includes("banned")}
          />
        </OrdersTd>
        <OrdersTd>
          <OrderTableButton
            role={"archive"}
            onClick={handleToggleArchiveModal}
            disabled={
              status === 1 || status === 2 || status === 6 || status === 7
            }
            title={"Archive the campaign"}
          />
        </OrdersTd>
      </OrdersTr>
      <Modal open={openCancelModal} onClose={handleToggleCancelModal}>
        <CancelOrderModal
          orderInfo={orderInfo}
          onClose={handleToggleCancelModal}
          fetchNewOrdersList={fetchNewOrdersList}
          openDelayedAttention={handleOpenDelayedModal}
        />
      </Modal>
      <Modal open={openDelayedModal} onClose={handleCloseDelayedModal}>
        <CancelDelayedOrderModal onClose={handleCloseDelayedModal}/>
      </Modal>
      <Modal open={openArchiveModal} onClose={handleToggleArchiveModal}>
        <ArchiveOrderModal
          orderInfo={orderInfo}
          onClose={handleToggleArchiveModal}
          fetchNewOrdersList={fetchNewOrdersList}
        />
      </Modal>
      <Popover
        style={{boxShadow: "unset", borderRadius: "12px"}}
        open={openPopover}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <PromotePopover campaignId={id}/>
      </Popover>
    </>
  );
};

export default OrdersCampaignItem;
