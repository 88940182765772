import React, {useEffect, useRef, useState} from 'react';
import {COUNTRIES_LIST, ICountryItem} from '../../../Constants/CountriesList';
import {
    SelectBlock,
    SelectItem,
    SelectItemSpan,
    SelectList,
    StyledSelectInput,
    StyledSelectInputLabel
} from '../Styles';
import ClearInputButton from '../../../UI/ClearInputButton';

interface ICountrySelectorProps {
    size: 'sm' | 'lg' | 'md';
    placeholder: string;
    selectedCountry: string | null;
    handleSelectCountry: (ISO: string | null) => void;
    inError?: boolean;
    countriesList?: ICountryItem[];
}

const CountrySelector: React.FC<ICountrySelectorProps> = ({
                                                              size,
                                                              placeholder,
                                                              handleSelectCountry,
                                                              inError,
                                                              selectedCountry,
                                                              countriesList = COUNTRIES_LIST,
                                                          }) => {
    const selectRef = useRef<HTMLDivElement>(null);
    const optionsListRef = useRef<HTMLDivElement>(null);
    const selectedItemRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [countryList, setCountryList] = useState(countriesList);
    const [openOptionsList, setOpenOptionsList] = useState(false);
    const [hoveredCountry, setHoveredCountry] = useState(0);
    const [inputFieldValue, setInputFieldValue] = useState<string>('');
    const [listIsFocused, setListIsFocused] = useState(false);

    useEffect(() => {
        setCountryList(countriesList);
    }, [countriesList]);

    useEffect(() => {
        setHoveredCountry(0);
    }, [countryList]);

    useEffect(() => {
        setHoveredCountry(0);
        if (inputFieldValue && inputFieldValue !== '') {
            const filteredCountriesList = countriesList.filter((country) => country.Alias.some((alias) => alias.toLowerCase().includes(inputFieldValue.toLowerCase())));
            setCountryList(filteredCountriesList);
        } else {
            setCountryList(countriesList);
        }
    }, [inputFieldValue]);

    useEffect(() => {
        if (selectedCountry) {
            const correctCountry = countriesList.find((c) => c.ISO === selectedCountry);

            setInputFieldValue(correctCountry ? correctCountry.Country : selectedCountry);
        } else if (selectedCountry === null) {
            setInputFieldValue('');
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (optionsListRef.current && selectedItemRef.current) {
            const parentRect = optionsListRef.current.getBoundingClientRect();
            const childrenRect = selectedItemRef.current.getBoundingClientRect();

            if (childrenRect.bottom > parentRect.bottom) {
                // Если элемент находится ниже видимой области списка, прокручиваем вниз
                optionsListRef.current.scrollTop += childrenRect.bottom - parentRect.bottom;
            } else if (childrenRect.top < parentRect.top) {
                // Если элемент находится выше видимой области списка, прокручиваем вверх
                optionsListRef.current.scrollTop -= parentRect.top - childrenRect.top;
            }
        }
    }, [hoveredCountry]);

    useEffect(() => {
        const handleCloseCountryList = (event: MouseEvent) => {
            const {target} = event;
            if (target instanceof Node && !selectRef.current?.contains(target)) {
                setOpenOptionsList(false);
            }
        };

        window.addEventListener('click', handleCloseCountryList);

        return () => window.removeEventListener('click', handleCloseCountryList);
    }, []);

    const handleClearInputField = () => {
        setInputFieldValue('');
        handleSelectCountry(null);
    };

    const handleChangeSearchFieldValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputFieldValue(event.target.value);

        if (event.target.value === '') {
            handleSelectCountry(null);
        }
    };

    const handleKeyDownArrow = (e: React.KeyboardEvent<HTMLInputElement>) => {
        setOpenOptionsList(true);
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            if (hoveredCountry === 0) {
                setHoveredCountry(countryList.length - 1);
            } else {
                setHoveredCountry((prevIndex) => prevIndex - 1);
            }
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            if (hoveredCountry === countryList.length - 1) {
                setHoveredCountry(0);
            } else {
                setHoveredCountry((prevIndex) => prevIndex + 1);
            }
        } else if (e.key === 'Enter') {
            e.preventDefault();
            handleSelectCountry(countryList[hoveredCountry].ISO);
            setHoveredCountry(0);
            setOpenOptionsList(false);
            handleDropInputFocus();
        }
    };

    const handleDropInputFocus = () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };


    return (
        <SelectBlock data-size={size} ref={selectRef}>
            <StyledSelectInputLabel data-list-is-open={openOptionsList}>
                <StyledSelectInput
                    ref={inputRef}
                    onKeyDown={handleKeyDownArrow}
                    value={inputFieldValue}
                    placeholder={placeholder}
                    onChange={handleChangeSearchFieldValue}
                    onMouseDown={() => {
                        setOpenOptionsList(!openOptionsList);
                        setListIsFocused(!listIsFocused);
                    }}
                    onFocus={() => {
                        setListIsFocused(true);
                        setOpenOptionsList(true);
                    }}
                    onBlur={() => {
                        setListIsFocused(false);
                        setOpenOptionsList(false);
                    }}
                    data-in-error={inError}
                    data-list-is-open={openOptionsList && countryList.length > 0}
                />
                {(inputFieldValue !== '' || selectedCountry) &&
                    <ClearInputButton onClick={handleClearInputField} fieldType={'select'}/>}
            </StyledSelectInputLabel>
            {!!(openOptionsList && countryList.length) &&
                <SelectList data-is-focused={listIsFocused} ref={optionsListRef}>
                    {countryList.map((country, index) => (
                        <SelectItem
                            data-active={hoveredCountry === index}
                            key={country.ISO}
                            onMouseDown={() => {
                                setOpenOptionsList(false);
                                handleSelectCountry(country.ISO);
                            }}
                            ref={hoveredCountry === index ? selectedItemRef : null}
                        >
                            <SelectItemSpan>
                                {country.Country}
                            </SelectItemSpan>
                        </SelectItem>
                    ))}
                </SelectList>
            }
        </SelectBlock>
    );
};

export default CountrySelector;