import React, {forwardRef, useState} from 'react';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import BasicButton from '../../../../../../../UI/BasicButton';
import {setDataToStorage} from '../../../../../../../Utils/localStorageHelpers';
import basicElements from '../../../../../../../Theme/basicElements.module.scss';
import modalsElements from '../../../../../../../Theme/modalsElements.module.scss';
import styled from './styled.module.scss';

interface DelayAttentionModalProps {
  onClose: () => void;
}

const DelayAttentionModal = forwardRef(({onClose}: DelayAttentionModalProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleSubmit = () => {
    if (dontShowAgain) {
      setDataToStorage('disable_delay_modal', dontShowAgain);
    }

    onClose();
  };

  return (
    <p className={modalsElements.modal_wrapper + ' ' + styled.delay_attention_modal_wrapper} ref={ref} tabIndex={-1}>
      <h3 className={modalsElements.modal_title + ' ' + styled.delay_attention_modal_title}>Delayed start</h3>
      <p className={basicElements.basic__content_text + ' ' + styled.delay_attention_modal_text}>
        The campaign will be launched in all the countries specified in the order at the same time, according to the
        time provided by you in your time zone.
      </p>
      <div className={styled.delay_checkbox_label}>
        <BasicCheckboxButton
          name={'dont show'}
          checked={dontShowAgain}
          onChange={(event) => setDontShowAgain(event.target.checked)}/>
        <span className={basicElements.basic__content_text + ' ' + styled.delay_checkbox_span}>Don't show it again</span>
      </div>
      <div className={styled.delay_attention_button_block}>
        <BasicButton onClick={handleSubmit} size={'lg'} text={'OK'} variant={'blue'}/>
      </div>
    </p>
  );
});

export default DelayAttentionModal;