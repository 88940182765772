import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {API_URL} from '../../Constants/ApiConstants';
import {LS_USER_TOKEN} from '../../Constants/LSConstants';
import {errorsActions} from '../Slices/errors.slice';
import {authActions} from '../Slices/auth.slice';
import {ICampaignInfo, ICampaignItem} from '../../Models/ApiModels';
import {INewCampaignObject} from '../../Pages/CreateCampaignPage/Models/CountryModel';

const {addError} = errorsActions;
const {setAuthenticated} = authActions;

interface IGetCampaignsParams {
  offset?: number,
  limit?: number,
  app: number | null,
  status: number | undefined,
  dateStart?: string,
  dateStop?: string
}

interface ICampaignIdData {
  campaign_id: number;
}

interface ICreateCampaignResponse {
  status: string;
  campaign_id: number;
}

interface IGetStatisticParams {
  dateStart: string,
  dateStop: string,
  apps: number[]
}

export interface IOrdersStatisticItem {
  activeCampaigns: string;
  application: number;
  application_icon: string;
  application_name: string;
  application_store_id: string;
  cancelledCampaigns: string;
  completedCampaigns: string;
  cost: number;
  countries: number;
  keywords: string;
  installsCount: string;
  ratesCount: string;
}

interface IGetStatisticGroupParams {
  dateStart: string,
  dateStop: string,
  app: number,
  groupBy: 'day' | 'week' | 'month'
}

export interface IGetStatisticGroupResponseItem {
  country: string;
  date: string | number;
  keyword: string;
  value: string;
}

export const CampaignApi = createApi({
  reducerPath: 'CampaignApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + '/campaign',
    prepareHeaders: headers => {
      const token: string | null = localStorage.getItem(LS_USER_TOKEN);

      if (token) headers.set('token', token);
    }
  }),
  tagTypes: ['CampaignList'],
  endpoints: (build) => ({
    getCampaigns: build.query<ICampaignItem[], IGetCampaignsParams>({
      query: ({offset = 0, limit = 999, app, status, dateStart, dateStop}) => ({
        url: '/get',
        params: {
          offset,
          limit,
          ...(app && ({app})),
          ...(status && ({status})),
          ...(dateStart && ({date_start: dateStart})),
          ...(dateStop && ({date_stop: dateStop})),
        }
      }),
      providesTags: ['CampaignList'],
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(addError(error.error));
          if (error.error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        }
      }
    }),
    cancelCampaign: build.mutation<any, ICampaignIdData>({
      query: ({campaign_id}) => ({
        url: '/cancel',
        method: 'post',
        body: {
          campaign_id
        }
      }),
      invalidatesTags: ['CampaignList'],
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(addError(error.error));
        }
      },
    }),
    archiveCampaign: build.mutation<any, ICampaignIdData>({
      query: ({campaign_id}) => ({
        url: '/delete',
        method: 'delete',
        body: {
          campaign_id
        }
      }),
      invalidatesTags: ['CampaignList'],
      async onQueryStarted(_, {queryFulfilled, dispatch}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          dispatch(addError(error.error));
        }
      },
    }),
    getCampaignInfo: build.query<ICampaignInfo, any>({
      query: (id: number) => ({
        url: '/info',
        method: 'get',
        params: {
          id
        }
      }),
    }),
    getStatistic: build.query<IOrdersStatisticItem[], IGetStatisticParams>({
      query: ({dateStart, dateStop, apps}) => ({
        url: 'statistic/all',
        method: 'get',
        params: {
          dateStart,
          dateStop,
          ...(apps.length && ({'app[]': apps}))
        }
      })
    }),
    getStatisticGroup: build.query<IGetStatisticGroupResponseItem[], IGetStatisticGroupParams>({
      query: ({dateStart, dateStop, app, groupBy}) => ({
        url: 'statistic/group',
        method: 'get',
        params: {
          dateStart,
          dateStop,
          app,
          groupBy
        }
      })
    }),
    createNewCampaign: build.mutation<ICreateCampaignResponse, INewCampaignObject>({
      query: ({app_id, keywords, date_start, date_stop, delayed_start, type, settings_message}) => ({
        url: 'new',
        method: 'put',
        body: {
          type,
          app_id,
          date_start,
          date_stop,
          settings_message,
          delayed_start,
          keywords
        }
      }),
      invalidatesTags: ['CampaignList'],
    }),
  })
});

export const {
  useLazyGetCampaignsQuery,
  useArchiveCampaignMutation,
  useCancelCampaignMutation,
  useGetCampaignInfoQuery,
  useCreateNewCampaignMutation,
  useGetStatisticQuery,
  useGetStatisticGroupQuery,
  useLazyGetStatisticGroupQuery
} = CampaignApi;