import React, {useCallback, useEffect, useState} from 'react';
import DelayStartRow from './Components/DelayStartRow';
import {ATTRIBUTES_VALUES} from './Constants/AttributesValues';
import {useAppSelector} from '../../../../../Hooks/redux/useAppSelector';
import ConsoleVisibilityRow from './Components/ConsoleVisibilityRow/ConsoleVisibilityRow';
import LaunchAppRow from './Components/LaunchAppRow/LaunchAppRow';
import DistributeRow from './Components/DistributeRow/DisributeRow';
import styled from './styled.module.scss';
import bitParser from "../../../../../Utils/BitParser";

interface ICreateCampaignAttributesViewProps {
  handleSetAttributesValue: (value: number) => void,
  handleSetDelayStartValue: (timeString: string | null) => void,
  handleSetDelayStartAttribute: (value: boolean) => void;
}

const CreateCampaignAttributesView: React.FC<ICreateCampaignAttributesViewProps> = ({
                                                                                      handleSetAttributesValue,
                                                                                      handleSetDelayStartValue,
                                                                                      handleSetDelayStartAttribute
                                                                                    }) => {
  // Модуль отвечает за сохранения выбранных аттрибутов в стейт и обратный парсинг значения стейта в массив.

  const [selectedCheckboxesValues, setSelectedCheckboxesValues] = useState<string[]>([]);

  const {attributesValue, activeScenario} = useAppSelector(state => state.newOrderSlice);

  const setCheckboxesValue = useCallback((attributesList: string[]) => {
    //функция, переводящая список параметров в битовое значение и сохраняющая значение в стейт
    const valuesArr = attributesList.map(attribute => ATTRIBUTES_VALUES[attribute]);

    handleSetAttributesValue(valuesArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0));
  }, [handleSetAttributesValue]);

  const handleSetSelectedCheckboxesValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    //функция, управляющая списком выбранных чекбоксов и отправка их в родителя в виде числа
    const newArr: string[] = [...selectedCheckboxesValues];

    if (event.target.checked) {
      newArr.push(event.target.value);
    } else {
      newArr.splice(newArr.indexOf(event.target.value), 1);
    }

    setCheckboxesValue(newArr);
  };

  const getCheckboxesAttributesArray = useCallback((attributesValue: number) => bitParser(attributesValue), []);

  useEffect(() => {
    setSelectedCheckboxesValues(getCheckboxesAttributesArray(attributesValue));
  }, [attributesValue]);


  return (
    <>
      <div className={styled.attributes_block}>
        <ConsoleVisibilityRow
          setCheckboxesValue={setCheckboxesValue}
          selectedCheckboxesValues={selectedCheckboxesValues}
          handleSetSelectedCheckboxesValue={handleSetSelectedCheckboxesValue}
          activeScenario={activeScenario}
        />
        <LaunchAppRow
          selectedCheckboxesValues={selectedCheckboxesValues}
          handleSetSelectedCheckboxesValue={handleSetSelectedCheckboxesValue}
          setCheckboxesValue={setCheckboxesValue}
          activeScenario={activeScenario}
        />
        <DistributeRow
          selectedCheckboxesValues={selectedCheckboxesValues}
          handleSetSelectedCheckboxesValue={handleSetSelectedCheckboxesValue}
          activeScenario={activeScenario}
        />
        <DelayStartRow
          handleSetDelayStartAttribute={handleSetDelayStartAttribute}
          handleSetDelayStartValue={handleSetDelayStartValue}
          activeScenario={activeScenario}
        />
      </div>
    </>
  );
};

export default CreateCampaignAttributesView;