import React from 'react';
import DateSelector from "../../../../../../Components/DateSelector";
import {useActions} from "../../../../../../Hooks/redux/useActions";
import {useAppSelector} from "../../../../../../Hooks/redux/useAppSelector";

const StatisticDateSelector = () => {
  const {dateStart, dateStop} = useAppSelector(state => state.myOrdersStatisticSlice);

  const {
    setStatisticDateStart,
    setStatisticDateStop,
  } = useActions();

  const handleSetDateStart = (date: string) => {
    setStatisticDateStart(date);
  };

  const handleSetDateStop = (date: string) => {
    setStatisticDateStop(date);
  };

  return (
    <DateSelector
      handleGetDateStart={handleSetDateStart}
      handleGetDateStop={handleSetDateStop}
      dateStart={dateStart}
      dateStop={dateStop}
      cleanable={false}
    />
  );
};

export default StatisticDateSelector;