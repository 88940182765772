import React, {FC, useMemo} from 'react';

import styles from '../tables.module.scss';
import basicStyles from '../../../../../../../Theme/basicElements.module.scss';
import {AppStatisticData} from "../../../models/detailsGroupTypes";
import {clsx} from "clsx";

interface DetailTableProps {
  countriesData: AppStatisticData | null;
  handleSetCountryISO: (ISO: string | null) => void;
}

const CountriesTable: FC<DetailTableProps> = ({countriesData, handleSetCountryISO}) => {
  const getCountriesTotal = useMemo(() => {
    if (!countriesData) return;

    return Object.values(countriesData).reduce((acc, cur) => acc + cur.total, 0);
  }, [countriesData]);

  const memoizedCountriesRows = useMemo(() => {
    if (!countriesData) return <></>;

    const countiesList = Object.keys(countriesData);

    return countiesList.map(country =>
      <tr className={clsx(styles.tr, styles.country)} key={country} onClick={() => handleSetCountryISO(country)}>
        <td
          className={basicStyles.basic__table_cell + ' ' + styles.td}
          data-align={'left'}>
          {countriesData[country].title}
        </td>
        <td
          className={basicStyles.basic__table_cell + ' ' + styles.td}
          data-align={'left'}
        >
          {countriesData[country].total}
        </td>
        <td
          className={basicStyles.basic__table_cell + ' ' + styles.td}
          data-align={'left'}
        >
          <div
            className={styles.detail_button}
          />
        </td>
      </tr>
    );
  }, [countriesData]);

  return (
    countriesData ?
      <table className={styles.table}>
        <thead className={styles.thead}>
        <tr>
          <th className={basicStyles.basic__table_cell + ' ' + styles.th} data-align="left"
          >Country
          </th>
          <th className={basicStyles.basic__table_cell + ' ' + styles.th} data-align="left">Total</th>
          <th className={basicStyles.basic__table_cell + ' ' + styles.th}></th>
        </tr>
        </thead>
        <tbody className={styles.tbody}>
        {memoizedCountriesRows}
        </tbody>
        <tfoot className={styles.tfoot}>
        <tr>
          <td
            className={basicStyles.basic__table_cell + ' ' + styles.td + ' ' + styles.sum}
            data-align={'left'}
          >
            All Countries
          </td>
          <td
            className={basicStyles.basic__table_cell + ' ' + styles.td + ' ' + styles.sum}
            data-align={'left'}
          >
            {getCountriesTotal}
          </td>
          <td
            className={basicStyles.basic__table_cell + ' ' + styles.td + ' ' + styles.sum}
            data-align={'left'}
          ></td>
        </tr>
        </tfoot>
      </table>
      : null
  );
};

export default CountriesTable;