import React from 'react';
import BasicButton from '../../../../../../../UI/BasicButton';
import {useAppSelector} from '../../../../../../../Hooks/redux/useAppSelector';
import {useGetAppsQuery} from '../../../../../../../Store/APIs/Apps.api';
import {
  getTotalCostValue,
  getTotalCurrentTypeName,
  getTotalKeywordsCountFromCountries
} from '../../../../../Helpers/CreateOrderHelpers';
import basicElements from "../../../../../../../Theme/basicElements.module.scss";
import styled from './styled.module.scss';

interface ICountriesTotalBlockProps {
  handleAddNewCountry: () => void;
}

const CountriesTotalBlock: React.FC<ICountriesTotalBlockProps> = ({handleAddNewCountry}) => {
  const {countriesList, app, type, attributesValue} = useAppSelector(state => state.newOrderSlice);
  const {user} = useAppSelector(state => state.clientDataSlice);

  const {data: appsList = []} = useGetAppsQuery({offset: 0, search: '', limit: 999});

  return (
    <div className={styled.total_wrapper}>
      <BasicButton onClick={handleAddNewCountry} size={'md'} text={'Add country'} variant={'blue'}/>
      <div className={styled.total_block}>
        {!!(app && type && user && appsList) &&
          <div className={styled.total_item}>
            <span className={basicElements.basic__content_text + ' ' + styled.total_value_span}>Campaign price:</span>
            <span className={basicElements.basic__content_text + ' ' + styled.total_value}>{getTotalCostValue(app, type, user, appsList, countriesList, attributesValue)?.toFixed(2)} $</span>
          </div>
        }
        {!!(countriesList.length && type) &&
          <div className={styled.total_item}>
            <span className={basicElements.basic__content_text + ' ' + styled.total_value_span}>Total {getTotalCurrentTypeName(type)}:</span>
            <span className={basicElements.basic__content_text + ' ' + styled.total_value}>{getTotalKeywordsCountFromCountries(countriesList)}</span>
          </div>
        }
      </div>
    </div>
  );
};

export default CountriesTotalBlock;