import React from 'react';
import HelpButton from '../../../../../Components/HelpButton';
import BasicCheckboxButton from '../../../../../../../UI/BasicCheckboxButton';
import {CreateOrderScenarioType} from '../../../../../Slicies/newCampaign.slice';
import styled from '../../styled.module.scss';

interface DistributeRowProps {
  selectedCheckboxesValues: string[];
  handleSetSelectedCheckboxesValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  activeScenario: CreateOrderScenarioType;
}

const DistributeRow: React.FC<DistributeRowProps> = ({
                                                       selectedCheckboxesValues,
                                                       handleSetSelectedCheckboxesValue,
                                                       activeScenario
                                                     }) => {
  return (
    <div className={styled.attribute_row}>
      <div className={styled.attribute_row_heading}>
        <div className={styled.attribute_row_label}>
          <span className={styled.attribute_row_title}>Distribute</span>
          <HelpButton
            popupText={'When this option is selected, installations will occur evenly throughout the day. If this option is not selected, installations are made as quickly as possible.'}/>
        </div>
        <BasicCheckboxButton
          name={'attributes'}
          value={'distribute'}
          checked={!!selectedCheckboxesValues.find(attribute => attribute === 'distribute')}
          onChange={handleSetSelectedCheckboxesValue}
          isDisabled={activeScenario === 'extend'}
        />
      </div>
      <span className={styled.attribute_row_title} data-is-disabled={!selectedCheckboxesValues.find(attribute => attribute === 'distribute')}>
        Throughout the day
      </span>
    </div>
  );
};

export default DistributeRow;