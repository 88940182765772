import React from 'react';
import styled from './styled.module.scss';

interface IHelpButtonProps {
  popupText: string;
  side?: 'left' | 'right';
}

const HelpButton: React.FC<IHelpButtonProps> = React.memo(({popupText, side = 'right'}) => {
  return (
    <div className={styled.help_button_container}>
      <div className={styled.help_button}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.0003 18.3334C14.6027 18.3334 18.3337 14.6025 18.3337 10.0001C18.3337 5.39771 14.6027 1.66675 10.0003 1.66675C5.39795 1.66675 1.66699 5.39771 1.66699 10.0001C1.66699 14.6025 5.39795 18.3334 10.0003 18.3334Z"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
          <path
            d="M9.79696 10.8714V10.6441C9.79696 9.90795 10.2516 9.51823 10.7063 9.20429C11.1501 8.90119 11.5939 8.51149 11.5939 7.79703C11.5939 6.8011 10.7929 6 9.79696 6C8.80104 6 8 6.8011 8 7.79703"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M9.79225 13.4587H9.80199" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
      <div className={styled.help_popup} data-side={side}>
        <p className={styled.help_popup_text}>{popupText}</p>
      </div>
    </div>
  );
});

export default HelpButton;