import React, {useEffect, useMemo, useState} from 'react';
import {IStatisticResponse, useGetAppStatisticQuery} from '../../../../../../Store/APIs/Apps.api';
import {getFormattedDate, getOneWeekAgo} from '../../../../../../Utils/DateUtils';
import DateSelector from '../../../../../../Components/DateSelector';
import BasicButton from '../../../../../../UI/BasicButton';
import RemoveCrossButton from '../../../../../../UI/RemoveCrossButton';
import {IAppItem} from '../../../../../../Models/ApiModels';
import {Loader} from 'rsuite';
import BasicStoreIcon from '../../../../../../UI/icons/BasicStoreIcon';
import {utils, writeFile} from 'xlsx';
import moment from 'moment';
import {COUNTRIES_LIST, ICountryItem} from "@/Constants/CountriesList";
import CountryFilter
    from "@/Pages/MyAppsPage/Modules/AppsCardsList/Modals/AppStatisticModal/CountryFilter/CountryFilter";

import modalElements from '@/Theme/modalsElements.module.scss';
import basicElements from '@/Theme/basicElements.module.scss';
import styles from './AppStatisticModal.module.scss';
import {clsx} from "clsx";

interface IStatisticProps {
    appInfo: IAppItem;
    onClose: () => void;
}

const AppStatisticModal = React.forwardRef<HTMLDivElement, IStatisticProps>(({appInfo, onClose}, ref) => {
    const [dateStart, setDateStart] = useState(getOneWeekAgo(getFormattedDate(new Date())));
    const [dateStop, setDateStop] = useState(getFormattedDate(new Date()));
    const [countriesList, setCountriesList] = useState<ICountryItem[]>([]);
    const [selectedCountriesList, setSelectedCountriesList] = useState<ICountryItem[]>([]);
    const [dataExport, setDataExport] = useState<IStatisticResponse[]>([]);

    const {data = [], isLoading} = useGetAppStatisticQuery({id: appInfo.id, dateStart, dateStop});

    console.log(data)

    useEffect(() => {
        if (data.length > 0) {
            const newCountriesISO = Array.from(new Set(data.map(row => row.country)));
            const newCountriesList: ICountryItem[] = newCountriesISO.map((ISO: string) => (COUNTRIES_LIST.find(country => country.ISO === ISO)) as ICountryItem)

            setCountriesList(newCountriesList)
        }
    }, [data]);

    const handleSetDateStart = (date: string) => {
        setDateStart(date);
    };

    const handleSetDateStop = (date: string) => {
        setDateStop(date);
    };

    const handleExport = () => {
        if (data.length) {
            console.log(data);

            const headerRow = ['Keys', ...Object.keys(dataExport[0]).filter((key) => key !== 'key')];
            const worksheetData = [headerRow];
            dataExport.forEach(obj => {
                const row = [obj.key];
                Object.keys(obj).forEach((key) => {
                    if (key !== 'key') {
                        row.push(obj[key]);
                    }
                });
                worksheetData.push(row);
            });

            const ws = utils.aoa_to_sheet(worksheetData);
            const wb = utils.book_new();

            utils.book_append_sheet(wb, ws, `Data`);

            writeFile(wb, `statistic_report_${appInfo.url.includes('apple') ? 'AppStore' : 'PlayMarket'}_${appInfo.name.replace(/ /g, '_')}_${moment(dateStart).format('YYYY-MM-DD')}_-_${moment(dateStop).format('YYYY-MM-DD')}.xlsx`);
        }
    };

    const handleCountrySelect = (ISO: string | null) => {
        const countryObject = COUNTRIES_LIST.find(country => country.ISO === ISO);
        const countryInState = Boolean(selectedCountriesList.find(country => country.ISO === ISO));

        if (countryObject && !countryInState) {
            setSelectedCountriesList([...selectedCountriesList, countryObject]);
        }
    };

    const handleCountryDelete = (ISO: string) => {
        setSelectedCountriesList((prevState) =>
            prevState.filter((country) => country.ISO !== ISO)
        );
    }

    const memoizedTable = useMemo(() => {
        if (!data.length) {
            return (<></>);
        }

        let filteredData: IStatisticResponse[] = [];
        const dateList = Object.keys(data[0]).filter(key => key !== 'key');

        if (!selectedCountriesList.length) {
            filteredData = data;
        } else {
            selectedCountriesList.map(country => {
                const filteredCountryData = data.filter(row => row.country === country.ISO);
                filteredData = [...filteredData, ...filteredCountryData];
            });
        }

        const groupedData: { [country: string]: IStatisticResponse[] } = {};
        filteredData.forEach(row => {
            if (!groupedData[row.country]) {
                groupedData[row.country] = [];
            }
            groupedData[row.country].push(row);
        });

        const countryTotals: { [key: string]: { [date: string]: number } } = {};
        const globalTotals: { [date: string]: number } = {};

        filteredData.forEach((row) => {
            if (!countryTotals[row.country]) {
                countryTotals[row.country] = {};
            }

            dateList.forEach(date => {
                if (date !== 'country') {
                    const value = parseFloat(row[date]) || 0;
                    countryTotals[row.country][date] = (countryTotals[row.country][date] || 0) + value;
                    globalTotals[date] = (globalTotals[date] || 0) + value;
                }
            });
        });

        setDataExport(filteredData);

        const allRows: JSX.Element[] = [];
        Object.keys(groupedData).forEach((country, countryIndex) => {
            groupedData[country].forEach((stat, index) => {
                allRows.push(
                    <tr key={`${stat.key}-${stat.country}-${index}`}>
                        <td className={clsx(basicElements.basic__table_cell, styles.td)}
                            data-role={'country'}>
                            {stat.country}
                        </td>
                        <td className={clsx(basicElements.basic__table_cell, styles.td)}
                            data-role={'key'}>
                            {stat.key}
                        </td>
                        {dateList?.map(date => (
                            date !== 'country' &&
                            (
                                <td className={clsx(basicElements.basic__table_cell, styles.td)}
                                    key={`${stat.key}-${date}-${stat.country}`}>
                                    {stat[date]}
                                </td>
                            )
                        ))}
                    </tr>
                );
            });

            // итог по стране, если выбрано больше одной страны или не выбрано ни одной
            if (selectedCountriesList.length !== 1) {
                allRows.push(
                    <tr key={`total-${country}`}>
                        <td className={clsx(basicElements.basic__table_cell, styles.td)}
                            data-role={'country'}
                            data-total={'true'}>
                            {country}
                        </td>
                        <td className={clsx(basicElements.basic__table_cell, styles.td)}
                            data-role={'key'}
                            data-total={'true'}>
                            Total
                        </td>
                        {dateList?.map(date => (
                            date !== 'country' &&
                            (
                                <td className={clsx(basicElements.basic__table_cell, styles.td)}
                                    key={`total-${country}-${date}`}
                                    data-total={'true'}>
                                    {countryTotals[country][date]}
                                </td>
                            )
                        ))}
                    </tr>
                );
            }
        });

        // общий итог
        allRows.push(
            <tr key="global-total">
                <td className={clsx(basicElements.basic__table_cell, styles.td, styles.totalRow)}
                    data-total={'true'}>
                    All Countries
                </td>
                <td className={clsx(basicElements.basic__table_cell, styles.td, styles.totalRow)}
                    data-total={'true'}>
                    Total
                </td>
                {dateList?.map(date => (
                    date !== 'country' &&
                    (
                        <td className={clsx(basicElements.basic__table_cell, styles.td, styles.totalRow)}
                            key={`global-total-${date}`}
                            data-total={'true'}>
                            {globalTotals[date]}
                        </td>
                    )
                ))}
            </tr>
        );

        const rowsWithClasses = allRows.map((row, index) => {
            if (row.props['data-total'] === 'true') {
                return row;
            }
            return React.cloneElement(row, {
                className: index % 2 !== 0 ? styles.evenRow : '',
            });
        });

        return (
            <div className={styles.table}>
                {!!filteredData.length ?
                    <table>
                        <thead>
                        <tr>
                            <th className={clsx(basicElements.basic__table_cell, styles.th)}>Country</th>
                            <th className={clsx(basicElements.basic__table_cell, styles.th)}>Keys</th>
                            {dateList?.map(date => (
                                date !== 'country' &&
                                <th className={clsx(basicElements.basic__table_cell, styles.th)} key={date}>{date}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {rowsWithClasses}
                        </tbody>
                    </table> :
                    <span className={styles.emptyText}>No data</span>
                }
            </div>
        );
    }, [data, selectedCountriesList]);

    return (
        <div className={clsx(modalElements.modal_wrapper, styles.wrapper)} ref={ref} tabIndex={-1}>
            <div className={modalElements.close_button_block}>
                <RemoveCrossButton onClick={onClose}/>
            </div>
            <div className={styles.titleWrapper}>
                <BasicStoreIcon store={appInfo.url.includes('apple') ? 'apple' : 'google'}/>
                <h3 className={clsx(modalElements.modal_title, styles.title)}>{appInfo.name} statistics</h3>
            </div>
            {isLoading ?
                <Loader center size={'lg'}/> :
                <>
                    <div className={styles.controlWrapper}>
                        <DateSelector
                            dateStart={dateStart}
                            dateStop={dateStop}
                            handleGetDateStop={handleSetDateStop}
                            handleGetDateStart={handleSetDateStart}
                            cleanable={false}
                        />
                        <BasicButton onClick={handleExport} size={'md'} text={'Export XLSX'} variant={'blue'}
                                     disabled={!data.length}/>
                    </div>


                    <CountryFilter
                        selectedCountriesList={selectedCountriesList}
                        countriesList={countriesList}
                        handleCountrySelect={handleCountrySelect}
                        handleCountryDelete={handleCountryDelete}
                    />

                    {memoizedTable}
                </>
            }

        </div>
    );
});

export default AppStatisticModal;